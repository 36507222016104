import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { GcmChartComponent } from '../gcm-chart/gcm-chart.component';

@Component({
  selector: 'app-base-geography-widget',
  standalone: true,
  imports: [BaseWidgetComponent, GcmChartComponent],
  templateUrl: './base-geography-widget.component.html'
})
export class BaseGeographyWidgetComponent implements OnInit, OnChanges {
  @Input() widgets;
  @Input() byText: string = 'By Remaining Value';
  @Input() reflowEvent;
  @Input() currency: string = 'USD';
  error: string = '';
  chartStyle = { width: '99%', display: 'flex' };
  remainingValueByRegionMap = [];
  remainingValueByRegionMapChartOptions = null;
  continentData = []
  remainingValuesForGeography = [];
  widgetData;

  constructor(private highchartsService: HighchartsService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.widgets && (this.widgets['remainingValueByMapRegion'])) {
      this.widgetData = this.widgets['remainingValueByMapRegion']
      this.calculateValuesForGeographyChart()
    } else {
      this.error = 'No Results'
    }
  }

  calculateValuesForGeographyChart() {
    if (this.widgetData && this.widgetData.length > 0) {
      this.error = '';
      const self = this;
      this.remainingValuesForGeography = [];
      this.widgetData.map(function (obj) {
        let value = obj.value;
        let key = obj.key
        self.remainingValuesForGeography.push({ continentKey: key, continentValue: value })
      });

      let remainingValuesForGeographySum = 0;
      this.remainingValuesForGeography.map(rvg => {
        remainingValuesForGeographySum += rvg.continentValue;
      })
      let valueOfContinent, percentageForContinent;
      this.continentData = [];
      this.highchartsService.continentWithMapCodeMapping.forEach(ele => {
        if (this.remainingValuesForGeography.filter(rvg => rvg.continentKey === ele.continent).length > 0) {
          valueOfContinent = this.remainingValuesForGeography.filter(rvg => rvg.continentKey === ele.continent).map(rvg => rvg.continentValue)[0];
          percentageForContinent = ((valueOfContinent / remainingValuesForGeographySum) * 100).toFixed(0)
          this.continentData.push({ key: ele.code, value: valueOfContinent, percentage: percentageForContinent })
        }
        else {
          this.continentData.push({ key: ele.code, value: 0, percentage: '0' })
        }
      });

      this.remainingValueByRegionMap = [];
      this.continentData.forEach(element => {
        if (element.percentage === '0') {
          if (['eu', 'as', 'na'].includes(element.key)) {
            this.remainingValueByRegionMap.push({ 'hc-key': element.key, key: element.key, value: element.percentage, actualValue: element.value, color: this.highchartsService.colorCodesMappingWithContinent.filter(x => x.code === 'noData').map(x => x.color)[0] })
          }
          else if (element.key === 'other') {
            ['oc', 'af', 'sa'].forEach(ele => {
              this.remainingValueByRegionMap.push({ 'hc-key': ele, key: ele, value: element.percentage, actualValue: element.value, color: this.highchartsService.colorCodesMappingWithContinent.filter(x => x.code === 'noData').map(x => x.color)[0], dataLabels: { enabled: false } });
            });
            this.remainingValueByRegionMap.push({ 'hc-key': 'o', key: 'others', value: element.percentage, actualValue: element.value, color: this.highchartsService.colorCodesMappingWithContinent.filter(x => x.code === element.key).map(x => x.color)[0], dataLabels: {color : '#333339'} });
          }
        }
        else {
          if (element.key === 'other') {
            ['oc', 'af', 'sa'].forEach(ele => {
              this.remainingValueByRegionMap.push({ 'hc-key': ele, key: ele, value: element.percentage, actualValue: element.value, color: this.highchartsService.colorCodesMappingWithContinent.filter(x => x.code === 'other').map(x => x.color)[0], dataLabels: { enabled: false } });
            });
            this.remainingValueByRegionMap.push({ 'hc-key': 'o', key: 'others', value: element.percentage, actualValue: element.value, color: this.highchartsService.colorCodesMappingWithContinent.filter(x => x.code === element.key).map(x => x.color)[0], dataLabels: {color : '#333339'} });
          }
          else {
            this.remainingValueByRegionMap.push({ 'hc-key': element.key, key: element.key, value: element.percentage, actualValue: element.value, color: this.highchartsService.colorCodesMappingWithContinent.filter(x => x.code === element.key).map(x => x.color)[0] });
          }
        }
      });
      this.remainingValueByRegionMapChartOptions = this.highchartsService.getMapHighchartsOptions(this.remainingValueByRegionMap, this.currency);
    }
    else {
      this.error = this.highchartsService.noResultsText;
    }
  }
  
}