import { UserService } from './../../services/user.service';
import { Component, OnInit, NgZone, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from '../../services/contentful.service';
import { InvestorRepository } from 'src/app/services/repositories/investor.repository';
import { Investor } from 'src/app/models/classes/investor.class';
import { Subscription } from 'rxjs';
import { BreadcrumbDataService } from 'src/app/services/breadcrumb-data.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { EnvironmentVariablesService } from 'src/app/services/environment-variables.service';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { LogoutButtonComponent } from '../logout-button/logout-button.component';
import { InvestorDropdownComponent } from '../investor-dropdown/investor-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { GcmHeaderComponent } from '@gcm/gcm-ui-angular';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        CommonModule,
        LogoutButtonComponent,
        InvestorDropdownComponent,
        MatIconModule,
        NgbDropdownModule,
        GcmHeaderComponent,
        MatMenuModule,
        MatButtonModule
    ],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    title = 'app';
    user;
    UserEmail;
    displayName;
    error: any;
    welcomeLabelText: string = '';
    logoutIconUrl: string = '';
    logoutLabelText: string = '';
    tncVersion: number;
    currentTncVersion: number;
    showInvestorsDropList: boolean = false;
    selectedInvestorId: number;
    investorsDropdownData: Investor[] = [];
    $investors: Subscription;
    investorName: string = '';
    displayNameInitials: string = '';
    @Output() openSideNav: EventEmitter<any> = new EventEmitter();

    constructor(
        private zone: NgZone,
        public router: Router,
        private ContentfulService: ContentfulService,
        private investorRepository: InvestorRepository,
        public userService: UserService,
        private breadcrumbDataService: BreadcrumbDataService,
        public tourService: TourService,
        public evService: EnvironmentVariablesService,
        public activityTracking: ActivityTrackingService
    ) {
    }

    ngOnInit(): void {
        this.UserEmail = this.userService.getUserEmail();
        this.getContentfulData();
        this.getOktaUserInfo();
    }

    logoutClick(): void {
        this.userService.logoutApp();
    }

    getOktaUserInfo(): void {
        if (this.userService.isExternalUser()) {
            this.ContentfulService.getTermsValue().subscribe(tncDetails => {
                if (tncDetails) {
                    this.tncVersion = tncDetails.data.landingPage.sys.publishedVersion;
                    this.currentTncVersion = this.userService.getTermsAndConditionsAcceptedVersion();
                    if (this.tncVersion > this.currentTncVersion) {
                        this.zone.run(() => {
                            this.router.navigate(['/terms']);
                        });
                    }
                }
            });
        }
        this.displayName = this.userService.getUserFullName();
        this.getDisplayNameInitials();
    }

    getContentfulData(): void {
        this.ContentfulService.fetchCommonAssets('header', '').subscribe((result: any) => {
            this.error = result.error;
            this.logoutIconUrl = result?.data?.header.iconsCollection.items[0].iconImage.url;
        });
    }

    getDashboardQueryParams() {
        const investorId = this.userService.queryParams['investorId'] || this.userService.params['investorId'];
        const selectedReportingPeriod = this.breadcrumbDataService.getReportingPeriodForSelectedInvestor(investorId) || this.breadcrumbDataService.getReportingPeriodValue();
        let queryParams = {};
        queryParams['investorid'] = investorId?.toString();
        queryParams['reportingdate'] = selectedReportingPeriod;
        return queryParams;
    }

    setActiveInvestor() {
        const investorId = this.userService.userIsOnInvestorDashboard() ? this.userService.queryParams['investorId'] : this.userService.params['investorId'];
        this.userService.setCookieValue('selectedInvestorId', investorId);
        this.getInvestorName(investorId);
        this.investorRepository.setActiveEntity(investorId);
    }

    ngOnDestroy(): void {
        if (this.$investors) {
            this.$investors.unsubscribe();
        }
    }

    getInvestorName(id) {
        if (id) {
            let selectedInvestor = this.investorsDropdownData.filter(investor => investor.id === Number(id))[0];
            if (selectedInvestor?.name && selectedInvestor?.name?.length < 100) {
                this.investorName = selectedInvestor?.name
            } else {
                this.investorName = selectedInvestor?.name?.substring(0, 100) + "..."
            }
        }
    }

    getDisplayNameInitials() {
        this.displayNameInitials = this.displayName.split(/\W+/).map((name) => name[0]).join('').slice(0, 4);
    }

    showSideNav() {
        this.openSideNav.emit();
    }

    checkCurrentInvestorId() {
        if (this.userService?.queryParams || this.userService?.params) {
            return this.userService.queryParams['investorId'] || this.userService.params['investorId']
        } else {
            return false
        }
    }
}

