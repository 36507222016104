import { Pipe, PipeTransform } from "@angular/core";
import { MathComputationService } from "../services/math-computation.service";
@Pipe({
    name: 'percentageValueFormat',
    standalone: true
})

export class PercentageValueFormatPipe implements PipeTransform {
    constructor(private mathService: MathComputationService) { }

    transform(value: any): any {
        if (value !== undefined && value !== null) {
            if (value === 0) {
                return '-';
            }
            else {
                return (value * 100).toFixed(1) === '-0.0' ? '(0.0%)' : ((value < 0) ? '(' + Math.abs(Number(this.mathService.roundNumber(value * 100, 1, 1))) + '%)' : this.mathService.roundNumber(value * 100, 1, 1) + '%');
            }
        }
        else {
            return 'N/A';
        }
    }
}