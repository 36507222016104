import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class DateCalculationsService {
    dateRangeList: string[] = ["Last 7 days", "Last 30 days", "This Month", "This Quarter", "This Year"];
    constructor(
        private dateAdapter: DateAdapter<Date>
    ) { }

    //date calculations:
    calculateDateRange(selectedPeriod, startDate, endDate): [start: Date, end: Date] {
        const today = this.today;
        const year = this.dateAdapter.getYear(today);
        switch (selectedPeriod) {
        case this.dateRangeList[0]: {
            const start = this.dateAdapter.addCalendarDays(today, -6);
            return [start, today];
        }
        case this.dateRangeList[1]: {
            const start = this.dateAdapter.addCalendarDays(today, -29);
            return [start, today];
        }
        case this.dateRangeList[2]: {
            return this.calculateMonth(today);
        }
        case this.dateRangeList[3]: {
            return this.calculateQuarter(today);
        }
        case this.dateRangeList[4]: {
            const start = this.dateAdapter.createDate(year, 0, 1);
            const end = this.dateAdapter.createDate(year, 11, 31);
            return [start, end];
        }
        case 'Custom': {
            return [startDate, endDate]
        }
        default:
            return selectedPeriod;
        }
    }

    calculateMonth(forDay: Date): [start: Date, end: Date] {
        const year = this.dateAdapter.getYear(forDay);
        const month = this.dateAdapter.getMonth(forDay);
        const start = this.dateAdapter.createDate(year, month, 1);
        const end = this.dateAdapter.addCalendarDays(
        start,
        this.dateAdapter.getNumDaysInMonth(forDay) - 1
        );
        return [start, end];
    }

    public calculateQuarter(forDay: Date): [start: Date, end: Date] {
        const quarter = Math.floor((this.dateAdapter.getMonth(forDay) / 3));
        const today = new Date();
        const start = new Date(today.getFullYear(), quarter * 3, 1);
        const end = new Date(start.getFullYear(), start.getMonth() + 3, 0);
        return [start, end];
    }

    public get today(): Date {
        const today = this.dateAdapter.getValidDateOrNull(new Date());
        if (today === null) {
        throw new Error('date creation failed');
        }
        return today;
    }
}
