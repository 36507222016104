export class Routes {
  public static investors = 'api/investors';
  public static portfolioSeries = 'api/portfolioSeries';
  public static dashboardPortfolioSeries = 'api/dashboards/portfolioseries';
  public static dashboardInvestor = 'api/dashboards/investor';
  public static contactSupport = 'api/supportRequest';
  public static tutorialCompleted = 'api/user/tutorialcompleted';
  public static metadata = 'api/metadata';
  public static userActivityLog = 'api/user/userActivityLog'
  public static reportStatus = 'api/ReportStatus'
}