<div class="main-content mt-[65px] lg:mt-[82px]">
    <div class="px-[15px] lg:pl-[10rem] pb-3 lg:pt-3">
        <h1 class="font-bold text-5xl md:text-6xl pb-2">Document Review</h1>
        <ng-container>
            <form class="mt-[5px]" [formGroup]="searchForm">
                <div class="mt-[5px] flex justify-between">
                    <div class="flex">
                        <div class="me-2" *ngIf="fullInvestorList?.length > 0">
                            <mat-form-field appearance="outline" class="form-field">
                                <mat-select formControlName="investors" multiple #investorSelect placeholder="Investors"
                                    disableOptionCentering panelClass="mat-select-investors">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="investorSearch" placeholderLabel="Search"
                                            noEntriesFoundLabel="No Investors Found"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option (click)="investorOptionClick(investor.id)"
                                        *ngFor="let investor of searchedInvestorList"
                                        [value]="investor.id">{{investor.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="ms-2 me-2" *ngIf="fullPortfolioList?.length > 0">
                            <mat-form-field appearance="outline" class="form-field">
                                <mat-select formControlName="portfolios" multiple #portfolioSelect
                                    placeholder="Portfolios" disableOptionCentering panelClass="mat-select-portfolios">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="portfolioSearch" placeholderLabel="Search"
                                            noEntriesFoundLabel="No Portfolios Found"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option (click)="portfolioOptionClick(portfolio.id)"
                                        *ngFor="let portfolio of searchedPortfolioList"
                                        [value]="portfolio.id">{{portfolio.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="ms-2 me-2 select-date-range">
                            <mat-form-field appearance="outline" class="form-field">
                                <mat-select formControlName="documentDateFilter" #documentDateSelect
                                    placeholder="Document Date" disableOptionCentering panelClass="mat-select-date">
                                    <mat-select-trigger
                                        *ngIf="selectedDocumentDate">{{selectedDocumentDate}}</mat-select-trigger>
                                    <mat-option *ngFor="let dateRange of dateRangeList"
                                        [value]="dateRange">{{dateRange}}</mat-option>
                                    <mat-option (click)="dateRangePicker('document')"
                                        [value]="'Custom'">Custom</mat-option>
                                </mat-select>
                                <mat-date-range-input [rangePicker]="documentPicker" [formGroup]="documentDateRange"
                                    style="display: none;">
                                    <input matStartDate placeholder="Start date" formControlName="start" />
                                    <input matEndDate placeholder="End date" formControlName="end"
                                        (dateChange)="selectDatePicker('document')" />
                                </mat-date-range-input>
                                <mat-date-range-picker #documentPicker
                                    panelClass="mat-date-picker"></mat-date-range-picker>
                            </mat-form-field>
                        </div>

                        <div class="ms-2 me-2 select-date-range">
                            <mat-form-field appearance="outline" class="form-field">
                                <mat-select formControlName="reportGenerationDateFilter" #reportGenerationDateSelect
                                    placeholder="Generation Date" disableOptionCentering panelClass="mat-select-date">
                                    <mat-select-trigger
                                        *ngIf="selectedReportGenerationDate">{{selectedReportGenerationDate}}</mat-select-trigger>
                                    <mat-option *ngFor="let dateRange of dateRangeList"
                                        [value]="dateRange">{{dateRange}}</mat-option>
                                    <mat-option (click)="dateRangePicker('reportGeneration')"
                                        [value]="'Custom'">Custom</mat-option>
                                </mat-select>
                                <mat-date-range-input [rangePicker]="reportGenerationPicker"
                                    [formGroup]="reportGenerationDateRange" style="display: none;">
                                    <input matStartDate placeholder="Start date" formControlName="start" />
                                    <input matEndDate placeholder="End date" formControlName="end"
                                        (dateChange)="selectDatePicker('reportGeneration')" />
                                </mat-date-range-input>
                                <mat-date-range-picker #reportGenerationPicker
                                    panelClass="mat-date-picker"></mat-date-range-picker>
                            </mat-form-field>
                        </div>

                        <div class="ms-2 me-2">
                            <mat-form-field appearance="outline" class="form-field">
                                <mat-select formControlName="statusFilter" multiple #statusSelect
                                    placeholder="Report Status" disableOptionCentering>
                                    <mat-option *ngFor="let statusOption of statusOptions"
                                        [value]="statusOption.value">{{statusOption.display}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex btn-group">
                        <div>
                            <button type="submit" class="submit-btn" (click)="applyFilters()"> Apply </button>
                        </div>
                        <div>
                            <button class="clear-btn" (click)="clearFilters()">
                                Clear Filters </button>
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <mat-chip-set>
                        <mat-chip *ngFor="let selectedFilter of allFilters" class="filter-chip"
                            (removed)="removeFilter(selectedFilter)">
                            {{ selectedFilter }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip *ngFor="let investorFilter of investorFiltersNames" class="filter-chip"
                            (removed)="removeInvestorFilter(investorFilter)">
                            {{ investorFilter }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <mat-chip *ngFor="let portfolioFilter of portfolioFiltersNames" class="filter-chip"
                            (removed)="removePortfolioFilter(portfolioFilter)">
                            {{ portfolioFilter }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </form>
        </ng-container>
        <div class="card document-review-card">
            <div class="card-body p-0">
                <table mat-table matSort [dataSource]="dataSource" class="mx-auto w-100">

                    <!-- Portfolio Name Column -->
                    <ng-container matColumnDef="reportingName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-[35%]"> Portfolio Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="w-[35%]">{{element.reportingName}}
                        </td>
                    </ng-container>

                    <!-- Report Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-[15%]"> Report Status
                        </th>
                        <td mat-cell *matCellDef="let element" class="w-[15%]">{{getFullNameStatus(element.status)}}
                        </td>
                    </ng-container>

                    <!-- Report Generation Error Column -->
                    <ng-container matColumnDef="error">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-[20%]"> Report Generation Error
                        </th>
                        <td mat-cell *matCellDef="let element" class="w-[20%]">{{element.error}}</td>
                    </ng-container>

                    <!-- Document Date of Report Column -->
                    <ng-container matColumnDef="reportingPeriod">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-[12%]">
                            Document Date </th>
                        <td mat-cell *matCellDef="let element" class="w-[12%]">
                            {{element.reportingPeriod | date: 'MM/dd/yyyy'}}
                        </td>
                    </ng-container>

                    <!-- Report Generation Attempted Date Column -->
                    <ng-container matColumnDef="createdOn">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-[18%]">
                            Report Generation Attempted Date </th>
                        <td mat-cell *matCellDef="let element" class="w-[18%]">
                            {{element.createdOn | date: 'MM/dd/yyyy'}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns; let first = first"></tr>
                    <tr class="mat-row text-center" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                            No documents found
                        </td>
                    </tr>
                </table>
                <mat-paginator [length]="documents.totalCount" [pageSizeOptions]="pageSizes"
                    [showFirstLastButtons]="true" [pageSize]="pageSize" [pageIndex]="page" aria-label="Select page"
                    class="mat-paginator-sticky">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
