import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'standardStringFormat',
    standalone: true
})
@Injectable({
    providedIn: 'root'
})
export class StandardStringFormatPipe implements PipeTransform {
    transform(value: string): any {
        return value ?? 'N/A';
    }
}