import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-expanded-view-slider',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './expanded-view-slider.component.html',
  styleUrls: ['./expanded-view-slider.component.scss']
})
export class ExpandedViewSliderComponent {
  @Input() expandView = false;
  @Output() updateTableColumns: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  updateColumns(expandView) {
    this.updateTableColumns.emit(expandView)
  }
}
