import { Pipe, PipeTransform } from "@angular/core";
import { TvpiIrrFormatService } from "../services/tvpi-irr-format.service";
@Pipe({
    name: 'tvpiValueFormat',
    standalone: true
})

export class TvpiValueFormatPipe implements PipeTransform {
    constructor(private tvpiFormat: TvpiIrrFormatService) { }

    transform(value: any): any {
        return this.tvpiFormat.getTvpiValue(value)
    }
}
