import { Injectable } from "@angular/core";
import { IStepOption } from 'ngx-ui-tour-md-menu';
import { ContentfulService } from "./contentful.service";
import { ContentfulConfigService } from "./contentful-config.service";
import { Observable, ReplaySubject } from "rxjs";
import { ContentfulResource } from "../models/classes/contentful-resource.class";

@Injectable({
    providedIn: 'root'
})
export class NgTourStepsService {
    investorDashboardSteps: IStepOption[] = [];
    seriesDashboardSteps: IStepOption[] = [];
    holdingDashboardSteps: IStepOption[] = [];
    assetDashboardSteps: IStepOption[] = [];
    defaultOptions: IStepOption;
    private contentfulDataFetched$: ReplaySubject<boolean> = new ReplaySubject(0);

    constructor(private contentfulService: ContentfulService, private contentfulConfig: ContentfulConfigService) {
        this.contentfulService.fetchCommonAssets('clickThroughJourney', '', this.contentfulConfig.clickThroughJourney).subscribe((data: any) => {
            const stepData = data.data.landingPage.sectionsCollection.items;
            this.investorDashboardSteps = stepData[0].resourcesCollection.items.map((ele: ContentfulResource) => ({ anchorId: ele.key, title: ele.internalName, content: ele.value }));
            this.seriesDashboardSteps = stepData[1].resourcesCollection.items.map((ele: ContentfulResource) => ({ anchorId: ele.key, title: ele.internalName, content: ele.value }));
            this.holdingDashboardSteps = stepData[2].resourcesCollection.items.map((ele: ContentfulResource) => ({ anchorId: ele.key, title: ele.internalName, content: ele.value }));
            this.assetDashboardSteps = stepData[3].resourcesCollection.items.map((ele: ContentfulResource) => ({ anchorId: ele.key, title: ele.internalName, content: ele.value }));
            this.contentfulDataFetched$.next(true);
        })
        this.defaultOptions = {
            endBtnTitle: 'Done',
            prevBtnTitle: 'Previous',
            smoothScroll: true,
            enableBackdrop: true,
            duplicateAnchorHandling: 'registerFirst',
            popoverClass: 'tour-popup-wrapper',
            disablePageScrolling: true,
            centerAnchorOnScroll: true,
            showProgress: false,
            showArrow: false
        }
    }

    getContentfulDataFetchedValue(): Observable<boolean> {
        return this.contentfulDataFetched$.asObservable();
    }
}