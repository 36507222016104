import { Component, Input } from '@angular/core';
import Highcharts from 'highcharts';
import { DeviceType } from 'src/app/models/enums/breakpoint.enum';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { MathComputationService } from 'src/app/services/math-computation.service';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';

@Component({
  selector: 'app-cumulative-chart',
  standalone: true,
  imports: [BaseWidgetComponent, GcmChartComponent],
  templateUrl: './cumulative-chart.component.html',
  styleUrls: ['./cumulative-chart.component.scss']
})
export class CumulativeChartComponent {
  cumulativeChartStyle = { width: '100%', display: 'flex' };
  cumulativeChartOptions = {};
  @Input() cumulativeData;
  @Input() selectedAsOfDate;
  linelegendsymbol: any = null;
  error: "";
  reflowEvent;
  
  constructor(
    private breakpointService: BreakpointService,
    private currencyFormat: CurrencyFormatPipe,
    private mathService: MathComputationService
  ) {}

  ngOnChanges() {
    if (this.cumulativeData) {
      this.createCumulativeChart(this.cumulativeData);
    }
  }

  createCumulativeChart(portfolioData: any) {
    let self = this;
    let chartData: any = [];
    chartData = this.mathService.filterChartDataBeforeSpecificYear(portfolioData.portfolioBalanceSummary);
    chartData.forEach(element => {
      element.contributions = Math.abs(element.contributions);
      element['disNavSum'] = Math.abs(element.distributions) + Math.abs(element.nav);
      element['month'] = new Date(element.reportingPeriod).getMonth() + 1;
      element['year'] = new Date(element.reportingPeriod).getFullYear();
      let quarter = element['month'] / 3
      element['quarter'] = `Q${quarter}<br/>${element['year']}`
    });
    let sortedData = chartData.sort((a, b) => new Date(a.reportingPeriod).getTime() - (new Date(b.reportingPeriod)).getTime());
    let cumulativeChartData = [];
    if (sortedData) {
      sortedData = sortedData.filter(data => new Date(data['reportingPeriod']) <= new Date(this.selectedAsOfDate));
    }
    if (sortedData && sortedData.length >= 16) {
      let recentQuarterValues = sortedData.slice(Math.max(sortedData.length - 4, 0));
      cumulativeChartData = sortedData.filter(data => data['month'] === 12 && !recentQuarterValues.includes(data)).concat(recentQuarterValues);
    }
    else {
      cumulativeChartData = sortedData;
    }

    //Creating Cumulative widget with Highcharts
    this.linelegendsymbol = Highcharts['seriesTypes'].line.prototype.drawLegendSymbol;
    Highcharts['seriesTypes'].line.prototype.drawLegendSymbol = Highcharts['seriesTypes'].area.prototype.drawLegendSymbol;
    this.breakpointService.getDeviceType().subscribe((deviceType: DeviceType) => {
      const isMobileDevice = deviceType === DeviceType.Handset;
      this.cumulativeChartOptions = {
        chart: {
          height: 400
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: cumulativeChartData.map(x => x.quarter),
          labels: {
            staggerLines: isMobileDevice ? 2 : 0,
            style: {
              fontSize: isMobileDevice ? '10px' : '12px'
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          labels: {
            enabled: true,
            reserveSpace: true,
            formatter: function (context) {
              //show in millions, if less than 1 million then show 1 decimal point
              let fractionDigits = Math.abs(context.value) >= 1000000 ? 0 : 1
              let valToShow = self.currencyFormat.transform(Number(context.value), portfolioData.currency, 'Millions', false, false, fractionDigits, true);
              return valToShow;
            },
            style: {
              fontSize: isMobileDevice ? '10px' : '12px'
            }
          },
          gridLineDashStyle: 'Dash',
          gridLineWidth: 1,
          gridLineColor: '#e6e6e6'
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            cursor: 'default',
            fontWeight: 'light',
            fontSize: isMobileDevice ? '11px' : '13px'
          },
          itemHoverStyle: {
            transition: 'none'
          },
          symbolHeight: 12,
          symbolWidth: 12,
          x: -18
        },
        tooltip: {
          backgroundColor: 'rgb(18, 76, 135, 0.85)',
          borderColor: '#fff',
          borderRadius: 8,
          style: {
            color: '#fff',
            fontSize: isMobileDevice ? '11px' : '14px'
          },
          useHTML: true,
          formatter: function () {
            let valToShow = self.currencyFormat.transform(Number(this.y), portfolioData.currency, 'Millions', false, false, 1, true);
            const data = `${this.series.name} <br/>` + valToShow + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
            const footer = `${this.x}`
            return data + footer
          },
          borderWidth: 0.5,
          shadow: true
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false
            }
          },
          column: {
            stacking: 'normal',
            maxPointWidth: 42,
            dataLabels: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          }
        },
        series: [{
          name: 'Distributions',
          data: cumulativeChartData.map(x => x.distributions),
          type: 'column',
          color: '#124c87',
          states: {
            hover: {
              color: '#124c87'
            }
          }
        }, {
          name: 'Net Asset Value',
          data: cumulativeChartData.map(x => x.nav),
          type: 'column',
          color: '#dadcde',
          states: {
            hover: {
              color: '#dadcde'
            }
          }
        }, {
          name: 'Contributions',
          data: cumulativeChartData.map(x => x.contributions),
          type: 'line',
          color: '#d39a47',
          borderColor: '#d39a47',
          states: {
            hover: {
              lineWidth: 2,
            },
            inactive: {
              opacity: 1
            }
          },
          marker: {
            enabled: false
          },
          events: {
            legendItemClick: function () {
              return false;
            }
          }
        }]
      }
    })

  }
}
