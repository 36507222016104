import { Pipe, PipeTransform } from '@angular/core';
import { Many, orderBy } from 'lodash';

@Pipe({ name: 'sortBy', standalone: true })
export class SortByPipe implements PipeTransform {

    transform(value: any[], order: Many<boolean | "asc" | "desc"> = 'asc', column: string = ''): any[] {
        if (!value) { return value; } // no array
        if (value.length <= 1) { return value; } // array with only one item
        if (!column || column === '') {
            if (order === 'asc') { return value.sort() }
            else { return value.sort().reverse(); }
        } // sort 1d array
        return orderBy(value, [value => value[column]?.toString()?.toLowerCase() || value[column]], order);
    }
}