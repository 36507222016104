import { Component } from '@angular/core';
import { GcmNotificationsComponent } from '@gcm/gcm-ui-angular';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'gcm-app',
  standalone: true,
  imports: [GcmNotificationsComponent, HeaderComponent, SidenavComponent, CommonModule],
  templateUrl: './custom-gcm-app.component.html'
})
export class CustomGcmAppComponent {
  showSideNav: boolean = false;

  constructor() { }

  openSideNav() {
    this.showSideNav = !this.showSideNav;
  }
}
