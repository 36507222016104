<ng-container *ngIf="assets">
    <div class="row title-row mt-4">
        <div class="col-6">
            <div class="table-title d-flex text-md lg:text-2xl">Assets
                <app-info-icon tooltipClass="info-icon-top-2" tooltipPosition="right"
                    tooltipInfo="Underlying Portfolio Company, Real Estate, and Infrastructure assets for the Holding"
                    data-cy="assetTableTooltip">
                </app-info-icon>
            </div>
            <div class="since-inception-text mb-3 text-sm lg:text-md" data-cy="assetTableSubHeaderText">Since Inception
                | Expressed in {{currency}} (in millions)</div>
        </div>
        <div class="col-6 flex flex-row flex-wrap pl-0 expanded-export">
            <app-expanded-view-slider (updateTableColumns)="updateColumns($event)"
                [expandView]="expandView"></app-expanded-view-slider>
            <div class="text-right">
                <button type="button" *ngIf="assets && assets?.length > 0 && asOfDate;"
                    (click)="exportAssetsDataToCsv($event)"
                    class="btn export-csv-btn btn-outline-primary text-xs lg:text-md md:before:top-2 lg:before:top-3 before:top-[37px] before:right-[10.6em] lg:before:right-[9.6em]"
                    data-cy="exportCsvButton">Export to Excel</button>
            </div>
        </div>
    </div>

    <div class="card holdings-assets-card" data-cy="holdingsAssetCard"
        *ngIf="assets && assets?.length > 0; else noResults">
        <div class="overflow-x-auto" data-cy="scrollAssetsTable" (scroll)="scrollAssetsTableHandler($event)">
            <table mat-table matSort [dataSource]="dataSource" class="lg:w-full"
                [ngClass]="expandView ? 'w-[1400px]' : 'w-[1100px]'">
                <!-- Asset Name Column -->
                <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left text-sm lg:text-md"
                        [ngClass]="expandView ? 'width-14' : 'width-20'"
                        [ngClass]="displayShadowOnAssetsTable ? 'shadow-background' : 'normal-background'"> Asset Name
                    </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md font-bold"
                        [ngClass]="{'width-14' : expandView, 'width-20' : !expandView, 'asset-name' : asset.masterId !== 0, 'asset-name-disabled' : asset.masterId === 0, 
                        'shadow-background' : displayShadowOnAssetsTable, 'normal-background' : !displayShadowOnAssetsTable}" attr.data-cy="asset{{asset.masterId}}">
                        <a *ngIf="asset.masterId !== 0" routerLinkActive="active"
                            [routerLink]="['/investor', params['investorId'], 'series', params['seriesId'], 'holding',  params['holdingId'], 'asset', asset.masterId]"
                            [queryParams]="queryParams"
                            (click)="activityTracking.navigateToDashboard('Asset', asset, 'assets detail table')"
                            data-cy="assetName">
                            {{asset.name}}
                        </a>
                        <span *ngIf="asset.masterId == 0" data-cy="assetName">{{asset.name}}</span>
                    </td>
                </ng-container>

                <!-- Acquisition Date Column -->
                <ng-container matColumnDef="acquisitionDate">
                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                        class="text-left text-sm lg:text-md" [ngClass]="expandView ? 'width-10' : 'width-13'">
                        Acquisition Date </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md"
                        [ngClass]="expandView ? 'width-10' : 'width-13'" data-cy="assetDate">
                        {{asset.acquisitionDate | dateFormat: 'M/d/yy'}}
                    </td>
                </ng-container>

                <!-- Remaining Cost Basis Column -->
                <ng-container matColumnDef="remainingCostBasis">
                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef class="text-right pr-5"
                        [ngClass]="expandView ? 'width-10' : 'width-13'"> Remaining Cost Basis </th>
                    <td mat-cell *matCellDef="let asset" class="text-right pr-65"
                        [ngClass]="expandView ? 'width-10' : 'width-13'" data-cy="assetBalanceValue">
                        {{asset.remainingCostBasis | currencyFormat:currency:'Millions':true:true}}
                    </td>
                </ng-container>

                <!-- Remaining Market Value Column -->
                <ng-container matColumnDef="remainingMarketValue">
                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef class="text-right pr-5"
                        [ngClass]="expandView ? 'width-10' : 'width-13'"> Remaining Market Value </th>
                    <td mat-cell *matCellDef="let asset" class="text-right pr-65"
                        [ngClass]="expandView ? 'width-10' : 'width-13'" data-cy="assetBalanceValue">
                        {{asset.remainingMarketValue | currencyFormat:currency:'Millions':true:true}}
                    </td>
                </ng-container>

                <!-- Statement Date Column -->
                <ng-container matColumnDef="statementDate">
                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                        class="text-left text-sm lg:text-md" [ngClass]="expandView ? 'width-8' : 'width-13'">
                        Statement Date </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md"
                        [ngClass]="expandView ? 'width-8' : 'width-13'" data-cy="assetDate">
                        {{asset.mostRecentSoiDate | dateFormat: 'M/d/yy'}}
                    </td>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="sector">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left text-sm lg:text-md"
                        [ngClass]="expandView ? 'width-6' : 'width-13'"> Sector </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md"
                        [ngClass]="expandView ? 'width-6' : 'width-13'" data-cy="assetSector">
                        {{asset.sector}}
                    </td>
                </ng-container>

                <!-- Exit Date Column -->
                <ng-container matColumnDef="exitDate">
                    <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                        class="text-left text-sm lg:text-md" [ngClass]="expandView ? 'width-8' : 'width-13'"> Exit
                        Date </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md"
                        [ngClass]="expandView ? 'width-8' : 'width-13'" data-cy="assetDate">
                        {{asset.exitDate | dateFormat: 'M/d/yy'}}
                    </td>
                </ng-container>

                <!-- Expanded View Columns -->
                <!-- Sub-Industry Column -->
                <ng-container matColumnDef="subIndustry">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left text-sm lg:text-md width-8"
                        data-cy="expandedOnlyLabel"> Sub-Industry </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md width-10"
                        data-cy="assetSubIndustry">
                        {{asset.subIndustry}}
                    </td>
                </ng-container>

                <!-- Geography Column -->
                <ng-container matColumnDef="geography">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left text-sm lg:text-md width-8"
                        data-cy="expandedOnlyLabel"> Geography </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md width-8"
                        data-cy="assetRegion">
                        {{asset.mapRegion}}
                    </td>
                </ng-container>

                <!-- Country Column -->
                <ng-container matColumnDef="country">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left text-sm lg:text-md width-8"
                        data-cy="expandedOnlyLabel"> Country </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md width-8"
                        data-cy="assetCountry">
                        {{asset.country}}
                    </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left text-sm lg:text-md width-8"
                        data-cy="expandedOnlyLabel"> Status </th>
                    <td mat-cell *matCellDef="let asset" class="text-left text-sm lg:text-md width-8"
                        data-cy="assetStatus">
                        {{asset.status}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                <tr mat-row *matRowDef="let asset; columns: columnsToDisplay;"
                    tourAnchor="holding.dashboard.assetTable"></tr>

            </table>
        </div>

    </div>
    <ng-template #noResults>
        <div class="w-full series-table-error" data-cy="noResults">
            <div class="row">
                <div class="col-12">
                    <div class=" mt-3">
                        <div>
                            <p class="text-center error-message">No Results</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>