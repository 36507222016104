import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { HoldingDashboardDetails } from 'src/app/models/classes/holding-dashboard-details.class';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { CurrencySymbolPipe } from 'src/app/pipes/currency-symbol.pipe';
import { IrrValueFormatPipe } from 'src/app/pipes/irr-value-format.pipe';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { TvpiValueFormatPipe } from 'src/app/pipes/tvpi-value-format.pipe';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { ModalService } from 'src/app/services/modal-service.service';

@Component({
  selector: 'app-holding-dashboard-summary',
  standalone: true,
  imports: [
    CommonModule, 
    CurrencyFormatPipe, 
    TvpiValueFormatPipe, 
    IrrValueFormatPipe, 
    CurrencySymbolPipe,
    SafeHtmlPipe
  ],
  templateUrl: './holding-dashboard-summary.component.html',
  styleUrls: ['./holding-dashboard-summary.component.scss']
})
export class HoldingDashboardSummaryComponent {
  @Input() iconSet: KeyValuePair<string, string>;
  @Input() cfmanagerLogo: string = '';
  @Input() holdingTitle: string;
  @Input() holdingSummary: HoldingDashboardDetails;
  @Input() holdingSummaryText: string;
  showSummary: boolean = false;
  noResultsSeriesDetails: boolean = false;
  portfolioSeriesDetailsNoResults: boolean = false;

  constructor(
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private activityTracking: ActivityTrackingService
  ) { }

  openModal() {
    this.activityTracking.viewMoreSummary(this.holdingSummary.masterId, this.holdingTitle)
    this.modalService.showDefaultModalComponent(this.holdingTitle, this.holdingSummaryText)
  }

  setShowMoreVisibility(el: any) {
    return el.offsetHeight < el.scrollHeight ||
      el.offsetWidth < el.scrollWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.holdingSummaryText) {
      this.showSummary = this.holdingSummaryText !== '' ? true : false;
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
}
