import { Component, OnInit } from '@angular/core';
import { EnvironmentVariablesService } from '../services/environment-variables.service';
import { UserService } from '../services/user.service';
import { ContentfulService } from '../services/contentful.service';
import { GcmErrorComponent, GcmLoadingService } from '@gcm/gcm-ui-angular';

@Component({
  selector: 'app-auth-error',
  standalone: true,
  imports: [GcmErrorComponent],
  templateUrl: './auth-error.component.html',
  styleUrls: []
})
export class AuthErrorComponent implements OnInit {
  clientDocsUrl: string = 'https://docs.clientscope.com';
  emailAddressText: any;
  mailToEmailAddress: any;
  buttonLinks = [];

  constructor(
    private loading: GcmLoadingService,
    public evService: EnvironmentVariablesService,
    private userService: UserService,
    private ContentfulService: ContentfulService
  ) { }

  ngOnInit(): void {
    this.loading.setLoading(false);
    this.checkUserDocsAccess();
    this.getContentfulData();
  }

  getContentfulData(): void {
    this.ContentfulService.getPageNotFound(this.evService.contentful['previewFlag']).subscribe((result) => {
      this.emailAddressText = result?.data?.page404Error.emailAddressText;
      this.mailToEmailAddress = result?.data?.page404Error.mailToEmailAddress;
    });
  }

  checkUserDocsAccess() {
    if (this.userService.getApplicationAccess().indexOf('ClientDocument') > -1) {
      // check if user has access to client docs, if yes then show client docs as client docs url
      this.buttonLinks.push({
        goToButtonText: 'GO TO CLIENTDOCS',
        goToRouterLink: this.clientDocsUrl
      });
    } else if (this.userService.getApplicationAccess().indexOf('VISTAONE') > -1) {
      // check if user has access to vista one, if yes then show vista one as client docs url
      this.buttonLinks.push({
        goToButtonText: 'GO TO DOCUMENTS',
        goToRouterLink: 'https://docs.gcmlp.com/Grosvenor/Release10/me.get?dps.views.show&TOOLS'
      });
    }
  }
}
