import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { NotificationService } from '../services/notification.service';
import { HttpResponse } from '@angular/common/http';
import { GcmLoadingService } from '@gcm/gcm-ui-angular';

@Injectable({
  providedIn: 'root'
})
export class SnapshotService {
  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private loadingService: GcmLoadingService
  ) { }

  getSnapshot(seriesId: number, reportingDate: string, fileName: string, holdingId?: number, assetMasterId?: number) {
    this.loadingService.setLoading(true);
    const url = this.apiService.getSnapshotRoute(seriesId, reportingDate, holdingId, assetMasterId);
    this.apiService.downloadSnapshot(url).subscribe({
      next: (blob: HttpResponse<Blob>) => {
        let a = document.createElement('a');
        let file = URL.createObjectURL(blob.body);
        a.href = file;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.loadingService.setLoading(false);
      },
      error: (err) => {
        this.loadingService.setLoading(false);
        this.notificationService.showErrorNotification(err);
      }
    })
  }
}
