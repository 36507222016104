import { Pipe, PipeTransform } from "@angular/core";
import { TvpiIrrFormatService } from "../services/tvpi-irr-format.service";
@Pipe({
    name: 'irrValueFormat',
    standalone: true
})

export class IrrValueFormatPipe implements PipeTransform {
    constructor(private irrFormat: TvpiIrrFormatService) { }

    transform(value: any, isDealOrHoldingValue?: boolean): any {
        return this.irrFormat.getIrrValue(value,false, isDealOrHoldingValue);
    }
}
