<ng-template #customTemplate>
    <ul class="flex-column">
        <div>
            <li class="lg:hidden">
                <div class="name">
                    <small>Welcome,</small>
                    <br>
                    {{ displayName }}
                </div>
            </li>
            <br class="lg:hidden"/>
            <app-investor-dropdown class="lg:hidden" [updateWidth]="showMobileSideNav"></app-investor-dropdown>
            <br class="lg:hidden"/>
            <ng-container *ngFor="let nav of navigationItems">
                <li class="pointer" *ngIf="nav.openDocumentLinkIn">
                    <a href="{{nav.url}}" (click)="navLinkClick(nav)" [class.active]="activelink.includes(nav.url)">
                        <div class="img-wrap">
                            <img src="{{nav.activeStateImage.url}}" class="active-img" />
                            <img src="{{nav.inactiveStateImage.url}}" class="normal-img" />
                        </div>
                        <div *ngFor="let displayText of nav.displayText">{{displayText}}</div>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngFor="let linkItem of navigationItems">
                <li class="pointer" *ngIf="!linkItem.openDocumentLinkIn">
                    <a href="{{linkItem.url}}" target="_blank" (click)="navLinkClick(linkItem)">
                        <div class="img-wrap">
                            <img src="{{linkItem.inactiveStateImage.url}}" class="normal-img pointer" />
                        </div>
                        <div class="flex flex-wrap ml-[19px] mr-0 external-redirect">
                            <div>
                                <div *ngFor="let displayText of linkItem.displayText">{{displayText}}
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </ng-container>
            <br/>
        </div>
    </ul>
</ng-template> 

<mat-sidenav-container class="bg-background-color h-full w-full" autosize>
    <app-investor-dropdown class="lg:hidden" [updateWidth]="showMobileSideNav"></app-investor-dropdown>
    <mat-sidenav mode="side" opened fixedInViewport="true" fixedTopGap="60" class="gcm-sidenav opened">
        <gcm-sidenav [content]="customTemplate" [showAppName]="false"></gcm-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="gcm-sidenav-content bg-background-color-primary bg-opacity-75">
        <ng-template #defaultBodyTemplate><router-outlet></router-outlet></ng-template>
        <ng-container *ngTemplateOutlet="defaultBodyTemplate"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>