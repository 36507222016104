import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { IWidget } from 'src/app/models/interfaces/iwidget.interface';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { UserService } from 'src/app/services/user.service';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';
import { InfoIconComponent } from 'src/app/shared/info-icon/info-icon.component';

@Component({
  selector: 'app-commitment-value-widget',
  standalone: true,
  imports: [BaseWidgetComponent, MatTabsModule, GcmChartComponent, InfoIconComponent],
  templateUrl: './commitment-value-widget.component.html',
  styleUrls: ['./commitment-value-widget.component.scss']
})
export class CommitmentValueWidgetComponent implements OnInit, OnChanges, IWidget {

  constructor(private highchartsService: HighchartsService, private currencyFormat: CurrencyFormatPipe, private userService: UserService, private activityTracking: ActivityTrackingService) { }
  
  @Input() widgets: Record<string, KeyValuePair<string | number, number>[]>;
  @Input() currency: string;
  investmentTypeChartOptions = {};
  strategyChartOptions = {};
  vintageYearChartOptions = {};
  error: string = '';
  chartStyle = { width: '99%', display: 'flex' };
  strategyError: string;
  investmentTypeError: string;
  vintageYearError: string;
  tabs = ['Strategy','Investment Type', 'Vintage Year']

  buildChartOptions(commitmentValueData: KeyValuePair<string | number, number>[], remainingValueData: KeyValuePair<string | number, number>[]) {
    const self = this;
    const commitmentValueSum = commitmentValueData.reduce((sum, widget) => { return sum + widget.value }, 0);
    const remainingValueSum = remainingValueData.reduce((sum, widget) => { return sum + widget.value }, 0);
    return {
      chart: {
        height: 400,
        marginBottom: 75,
        marginRight: 0
      },
      plotOptions: {
        series: {
          dataLabels: {
            formatter: function () {
              return `${this.y}%`;
            }
          },
          states: {
            inactive: {
              opacity: 1
            }
          },
          events: {
            legendItemClick: function () {
              return false;
            }
          },
          keys: ['name','y','custom.value']
        },
        bar: {
          groupPadding: 0.125
        }
      },
      legend: {
        enabled: true,
        align: 'right',
        symbolRadius: 0,
        layout: 'vertical',
        itemMarginBottom: 5,
        itemStyle: {
          cursor: 'default',
          fontWeight: 'light',
          fontSize: '1em'
        },
        itemHoverStyle: {
          transition: 'none'
        },
        labelFormat: 'By {name}'
      },
      tooltip: {
        formatter: function () {
          const data = `${this.series.name} <br/>` + this.point.custom.value + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
          const footer = `<div>${this.key}</div>`
          return data + footer
        }
      },
      series: [{
        name: 'Commitment Value',
        color: '#4a87af',
        data: commitmentValueData.map(kvp => [kvp.key, Math.round(Math.abs(kvp.value / commitmentValueSum) * 1000) / 10, this.currencyFormat.transform(kvp.value, self.currency)])
      }, {
        name: 'Net Asset Value',
        color: '#be661b',
        data: remainingValueData?.map(kvp => [kvp.key, Math.round(Math.abs(kvp.value / remainingValueSum) * 1000) / 10, this.currencyFormat.transform(kvp.value, self.currency)])
      }]
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets && this.widgets) {
      this.investmentTypeError = this.widgets['commitmentsByInvestmentType']?.length > 0 || this.widgets['remainingValueByInvestmentType']?.length > 0 ? '' : this.highchartsService.noResultsText;
      this.strategyError = this.widgets['commitmentsByStrategy']?.length > 0 || this.widgets['remainingValueByStrategy']?.length > 0 ? '' : this.highchartsService.noResultsText;
      this.vintageYearError = this.widgets['commitmentsByVintageYear']?.length > 0 || this.widgets['remainingValueByVintageYear']?.length > 0 ? '' : this.highchartsService.noResultsText;

      // building chart options
      this.strategyChartOptions = this.buildChartOptions(this.widgets['commitmentsByStrategy'], this.widgets['remainingValueByStrategy']);
      this.investmentTypeChartOptions = this.buildChartOptions(this.widgets['commitmentsByInvestmentType'], this.widgets['remainingValueByInvestmentType']);
      this.vintageYearChartOptions = this.buildChartOptions(this.widgets['commitmentsByVintageYear'], this.widgets['remainingValueByVintageYear']);
    }
  }

  handleChange(event: MatTabChangeEvent) {
    this.activityTracking.widgetTabChange('Strategy/investment type/vintage year widget', this.tabs[event.index])
    this.userService.raygunTrackMatTabChange(event);
  }
}
