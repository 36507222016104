import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CurrencyFormatPipe } from '../../pipes/currency-format.pipe';
import { TvpiIrrFormatService } from 'src/app/services/tvpi-irr-format.service';
import { AssetClassPipe } from 'src/app/pipes/asset-class.pipe';
import { DeviceType } from 'src/app/models/enums/breakpoint.enum';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { MathComputationService } from 'src/app/services/math-computation.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { UserService } from 'src/app/services/user.service';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { InfoIconComponent } from 'src/app/shared/info-icon/info-icon.component';
import { CommonModule } from '@angular/common';
import { InfoTextBoxComponent } from 'src/app/shared/info-text-box/info-text-box.component';
import { CumulativeChartComponent } from './cumulative-chart/cumulative-chart.component';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';
import { SortByPipe } from 'src/app/pipes/sortBy.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import * as dayjs from 'dayjs'

@Component({
  selector: 'app-portfolio-summary',
  standalone: true,
  imports: [
    CommonModule, 
    InfoIconComponent, 
    InfoTextBoxComponent, 
    CumulativeChartComponent, 
    BaseWidgetComponent, 
    GcmChartComponent,
    SortByPipe,
    AssetClassPipe,
    DateFormatPipe,
    CurrencyFormatPipe,
    RouterModule
  ],
  templateUrl: './portfolio-summary.component.html',
  styleUrls: ['./portfolio-summary.component.scss']
})
export class PortfolioSummaryComponent {
  @Input() activeInvestorMetadata;
  @Input() investorPortfolioData;
  @Input() iconSet: {};
  @Input() selectedAsOfDate: string = '';
  cumulativeChartStyle = { width: '100%', display: 'flex' };
  cumulativeChartOptions = {};
  error: string = '';
  investorPortfolios: any = [];
  errorMessage: any;
  currency: string = 'USD';
  isMobileDevice: boolean = false;
  noInvDataMsg: string = 'No Investment Data Available';

  constructor(
    private router: Router,
    private currencyFormat: CurrencyFormatPipe,
    private assetClassPipe: AssetClassPipe,
    private tvpiIrrFormat: TvpiIrrFormatService,
    private breakpointService: BreakpointService,
    private mathService: MathComputationService,
    private tourService: TourService,
    private userService: UserService,
    public activityTracking: ActivityTrackingService
  ) { }

  ngOnInit(): void {
    let showTutorial = !this.userService.getTutorialComplete();
    if (showTutorial) {
      this.activityTracking.tourServiceStarted(false);
      this.tourService.start()
    }
  }

  ngOnChanges() {
    if (this.iconSet) {
      this.getPortfolioInfo()
    }
  }

  getPortfolioInfo() {
    this.investorPortfolios = []
    this.investorPortfolioData.forEach(portfolioData => {
      portfolioData['isExpanded'] = false;
      this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(portfolioData.qoQIrrChange, portfolioData.irr, portfolioData.priorIrr, this.iconSet, portfolioData);
      this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(portfolioData.qoQTvpiChange, portfolioData.tvpi, portfolioData.priorTvpi, this.iconSet, portfolioData, true);
      portfolioData['netTvpi'] = this.tvpiIrrFormat.getTvpiValue(portfolioData.tvpi);
      portfolioData['netIrr'] = this.tvpiIrrFormat.getIrrValue(portfolioData.irr);
      portfolioData.portfolioSeries.forEach(series => {
        series['isExpanded'] = false;
        this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(series.qoQIrrChange, series.irr, series.priorIrr, this.iconSet, series);
        this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(series.qoQTvpiChange, series.tvpi, series.priorTvpi, this.iconSet, series, true);
        series['assetClassImage'] = [];
        series['netTvpi'] = this.tvpiIrrFormat.getTvpiValue(series.tvpi);
        series['netIrr'] = this.tvpiIrrFormat.getIrrValue(series.irr);
        series.assetClasses.forEach(assetClass => {
          if (assetClass === 'ARS') {
            assetClass = 'Absolute Returns Strategy'
          }
          if (assetClass) {
            series['assetClassImage'].push([assetClass, this.iconSet[this.assetClassPipe.transform(assetClass)]]);
          }
        })
        this.checkIfSeriesHasCommitments(series);
        if (this.investorPortfolioData.length === 1 && portfolioData.portfolioSeries.length === 1) {
          this.expandSeriesPortfolio(series);
          series.hideButton = true;
        }
      })
      portfolioData['priorPeriod'] = portfolioData.reportingPeriod.slice(0,10) != this.selectedAsOfDate;
      this.investorPortfolios.push(portfolioData)
    })
  }

  checkIfSeriesHasCommitments(series) {
    if (series.portfolioSeriesBalanceSummary?.length > 0) {
      let currentPeriodSummary = series.portfolioSeriesBalanceSummary.filter(s => s.reportingPeriod == series.reportingPeriod)
      series['hasCommitments'] = currentPeriodSummary[0]?.hasInvestmentCommitments;
    }
    // if Zurich or SoCal then show true for hasCommitments value for now
    if (this.activeInvestorMetadata.id === 13216 || this.activeInvestorMetadata.id === 11752) {
      series['hasCommitments'] = true;
    }
  }

  redirectToSeriesPage(seriesData) {
    let seriesId: number = seriesData.displayPortfolioSeriesMasterId ?? seriesData.portfolioMasterId;
    let route = `/investor/${this.activeInvestorMetadata.id}/series/${seriesId}`;
    return route
  }

  getQueryParams(seriesData) {
    return {
      reportingdate: seriesData.reportingPeriod.slice(0, 10)
    }
  }

  expandSeriesPortfolio(seriesData: any, fromClick?: boolean) {
    seriesData.isExpanded = true;
    if (fromClick) {
      this.activityTracking.expandOrCollapseToggled('series visuals', seriesData, 'investor dashboard', seriesData.isExpanded);
    }
    if (seriesData.portfolioSeriesBalanceSummary.length > 0) {
      this.breakpointService.getDeviceType().subscribe((deviceType: DeviceType) => {
        this.isMobileDevice = deviceType === DeviceType.Handset;
        this.createCommittedFundedChart(seriesData);
        this.createNavDisConChart(seriesData);
        this.createNavDistributionChart(seriesData);
      })
    }
  }

  collapseSeriesPortfolio(seriesData: any) {
    seriesData.isExpanded = false;
    this.activityTracking.expandOrCollapseToggled('series visuals', seriesData, 'investor dashboard', seriesData.isExpanded);
  }

  createCommittedFundedChart(seriesData: any) {
    let self = this;
    seriesData['committedFundedChart'] = {
      chart: {
        type: 'column',
        height: 190,
        margin: 0,
        backgroundColor: '#fbfbfb',
        marginRight: 90
      },
      xAxis: {
        visible: false
      },
      yAxis: {
        visible: false
      },
      title: {
        text: ''
      },
      legend: {
        shadow: false,
        enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 13,
        y: 10,
        x: 10,
        labelFormatter: function () {
          if (this.name === "Total Committed") {
            let valToShow = self.currencyFormat.transform(Number(seriesData.commitment), self.currency);
            return '<div style="color: #88888f"><span>' + valToShow + '</span><br/><span style="font-weight: 400">' + this.name + '</span></div>';
          }
          if (this.name === "Capital Funded") {
            let valToShow = self.currencyFormat.transform(Number(seriesData.contributions), self.currency);
            let percentageToshow = (seriesData.capitalFundedPercent * 100).toFixed(2);
            return '<div style="color: #3e77a4"><span>' + valToShow + ' (' + percentageToshow + '%) </span><br/><span style="font-weight: 400">' + this.name + '</span></div>';
          }
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false
          }
        },
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
          events: {
            legendItemClick: function () {
              return false;
            }
          },
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          },
        }
      },
      series: [{
        name: 'Total Committed',
        color: '#d1d3d5',
        data: Array(1).fill(seriesData.commitment),
        pointPadding: 0.22,
        pointPlacement: 0.1,
        type: 'column'
      }, {
        name: 'Capital Funded',
        color: '#3e77a4',
        data: Array(1).fill(seriesData.contributions),
        pointPadding: 0.28,
        pointPlacement: 0.2,
        type: 'column'
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 425
          },
          chartOptions: {
            series: [{
              name: 'Total Committed',
              color: '#d1d3d5',
              data: Array(1).fill(seriesData.commitment),
              pointPadding: 0.35,
              pointPlacement: 0.1,
              type: 'column'
            }, {
              name: 'Capital Funded',
              color: '#3e77a4',
              data: Array(1).fill(seriesData.contributions),
              pointPadding: 0.4,
              pointPlacement: 0.2,
              type: 'column'
            }],
          }
        }]
      }
    }
  }

  createNavDistributionChart(seriesData: any) {
    let self = this;
    let navDistributionData: any = [];
    let navTitle = 'Net Asset Value';
    let disAndNavSum = Number(Math.abs(seriesData.distributions)) + Number(seriesData.nav);
    let disAndNavSumValToShow = self.currencyFormat.transform(disAndNavSum, self.currency);
    navDistributionData.push(['Distributions', Math.abs(seriesData.distributions)], [navTitle, seriesData.nav])
    seriesData['navDistributionChart'] = {
      chart: {
        type: 'pie',
        height: 190,
        margin: 0,
        backgroundColor: '#fbfbfb',
        marginRight: 90,
        marginTop: 10,
        marginLeft: -40
      },
      xAxis: {
        visible: false
      },
      yAxis: {
        visible: false
      },
      title: {
        verticalAlign: 'middle',
        x: -65,
        y: 20,
        style: {
          fontSize: '13px'
        },
        text: `<div style="color: #777777; text-align: center;" id="totalValueForChart"><span style="font-weight: 700;">${disAndNavSumValToShow}</span><br/><span style="font-weight: 400;">Total Value</span></div>`,
        useHTML: true
      },
      legend: {
        shadow: false,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 13,
        labelFormatter: function () {
          if (this.name === "Distributions") {
            let valToShow = self.currencyFormat.transform(Number(seriesData.distributions), self.currency);
            return '<div style="color: #be661b"><span>' + valToShow + '</span><br/><span style="font-weight: 400;">' + this.name + '</span></div>';
          }
          if (this.name === navTitle) {
            let valToShow = self.currencyFormat.transform(Number(seriesData.nav), self.currency);
            return '<div style="color: #3e77a4"><span>' + valToShow + ' </span><br/><span style="font-weight: 400;">' + this.name + '</span></div>';
          }
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          innerSize: '70%',
          events: {
            legendItemClick: function () {
              return false;
            }
          },
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1
            }
          },
          colors: ['#be661b', '#3e77a4']
        }
      },
      series: [{
        data: navDistributionData,
        type: 'pie',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 425
          },
          chartOptions: {
            chart: {
              height: 130
            },
            title: {
              style: {
                fontSize: '10px'
              }
            }
          }
        }]
      }
    }
  }

  createNavDisConChart(seriesData: any) {
    let chartData: any = [];
    chartData = this.mathService.filterChartDataBeforeSpecificYear(seriesData.portfolioSeriesBalanceSummary);
    chartData.forEach(element => {
      element.contributions = Math.abs(element.contributions);
      element['disNavSum'] = Math.abs(element.distributions) + Math.abs(element.nav);
      element['month'] = new Date(element.reportingPeriod).getMonth() + 1;
      element['year'] = new Date(element.reportingPeriod).getFullYear();
      let quarter = element['month'] / 3
      element['quarter'] = `Q${quarter}<br/>${element['year']}`
    });
    let sortedData = chartData.sort((a, b) => dayjs(a.reportingPeriod).valueOf() - (dayjs(b.reportingPeriod)).valueOf());
    let navDisConChartData = [];
    if (sortedData) {
      sortedData = sortedData.filter(data => new Date(data['reportingPeriod']) <= new Date(this.selectedAsOfDate));
    }
    if (sortedData && sortedData.length >= 16) {
      let recentQuarterValues = sortedData.slice(Math.max(sortedData.length - 4, 0));
      navDisConChartData = sortedData.filter(data => data['month'] === 12 && !recentQuarterValues.includes(data)).concat(recentQuarterValues);
    }
    else {
      navDisConChartData = sortedData;
    }

    seriesData['NavDisConChart'] = {
      chart: {
        height: 197,
        borderRadius: 8,
        backgroundColor: '#fbfbfb'
      },
      xAxis: {
        categories: navDisConChartData.map(x => x.quarter),
        tickLength: 10,
        tickWidth: 1,
        tickPosition: 'outside',
        labels: {
          staggerLines: this.isMobileDevice ? 2 : 0,
          style: {
            fontSize: this.isMobileDevice ? '10px' : '12px'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          align: 'right',
          reserveSpace: true,
          formatter: function (context) {
            return Number(context.value) > 0 ? Math.round(Number(context.value) / 1000000) + 'M' : context.value.toString();
          },
          style: {
            fontSize: '12px'
          }
        },
        maxPadding: 0.25,
        tickAmount: 3
      },
      title: {
        text: ''
      },
      legend: {
        shadow: false,
        align: 'center',
        verticalAlign: 'top',
        layout: 'vertical',
        itemStyle: {
          fontWeight: '400'
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        line: {
          shadow: false,
          borderWidth: 0,
          events: {
            legendItemClick: function () {
              return false;
            }
          },
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          }
        },
        area: {
          shadow: false,
          events: {
            legendItemClick: function () {
              return false;
            }
          },
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          }
        }
      },
      series: [{
        data: navDisConChartData.map(x => x.disNavSum),
        type: 'area',
        name: 'NAV + Distributions',
        color: '#d4f2fd',
        lineColor: '#6ea1c1',
        lineWidth: 1.5,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
      },
      {
        data: navDisConChartData.map(x => x.contributions),
        type: 'line',
        name: 'Contributions',
        color: '#40a42b',
        lineWidth: 3,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      }]
    }
  }
}
