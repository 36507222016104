import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard {
    constructor(private userService: UserService, private router: Router) { }
    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):Promise<boolean | UrlTree> {
            const isExternal = await this.userService.isExternalUser();
            if (isExternal) {
                this.router.navigate(['/404'])
                return false
            }
            return true
    }
}