import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from '../services/contentful.service';
import { EnvironmentVariablesService } from '../services/environment-variables.service';
import { ProxyService } from '../services/proxy.service';
import { LoggingService } from '../services/logging.service';
import { ContentfulConfigService } from '../services/contentful-config.service';
import { UserService } from '../services/user.service';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { GcmLoadingService } from '@gcm/gcm-ui-angular';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [SafeHtmlPipe],
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsComponent implements OnInit {
  oktaUserInfoEndPoint: string = this.evService.oktaConfigs['Issuer'] + '/api/v1/apps/' + this.evService.oktaConfigs['ClientId'] + '/users/';
  error: any;
  backgroundImageUrl: string = '';
  pageHeading: string = '';
  mailToEmailAddress: string = '';
  emailAddressText: string = '';
  declineButtonText: string = '';
  acceptButtonText: string = '';
  LogoImageUrl: string;
  copyrightText: string = '';
  pageContent: string;
  tncVersion: number;
  currentTncVersion: number;
  userMode: string;
  pageLabels: any;

  constructor(
    private zone: NgZone,
    private router: Router,
    private userService: UserService,
    private ContentfulService: ContentfulService,
    private evService: EnvironmentVariablesService,
    private proxy: ProxyService,
    private loggingService: LoggingService,
    private contentfulConfig: ContentfulConfigService,
    private loadingService: GcmLoadingService
  ) { }

  ngOnInit(): void {
    if (!this.userService.isExternalUser()) {
      this.redirectToDashboard();
    }
    else {
      this.loadingService.setLoading(true);
      // moving all the code to this function instead of calling contentful twice for the same thing
      this.getContentfulData();
    }
  }

  redirectToDashboard() {
    this.zone.run(() => {
      this.router.navigate(['/dashboard']);
    });
  }

  acceptTnC() {
    this.loadingService.setLoading(true);
    this.proxy.updateTnC(this.tncVersion).subscribe(response => {
      this.loggingService.log(localStorage.getItem('userEmail') + ' have accepted - Updated Terms and conditions - ', response);
      this.loadingService.setLoading(false);
      this.userService.refreshOktaIdToken().then(res => {
        //////// Page redirection to Dashboard //////////
        this.redirectToDashboard();
      })
    });
  }

  decline() {
    this.loadingService.setLoading(true);
    this.proxy.updateTnC(this.currentTncVersion).subscribe(response => {
      this.loggingService.log(localStorage.getItem('userEmail') + ' have declined - Updated Terms and conditions - ', response);
      this.loadingService.setLoading(false);
      ////////// Logout user //////////
      this.userService.logoutApp();
    });
  }

  getContentfulData(): void {
    this.ContentfulService.getTermsValue().subscribe(tncDetails => {
      if (tncDetails) {
        this.pageLabels = this.ContentfulService.buildKeyValueLabels(tncDetails.data.landingPage.sectionsCollection.items[1].resourcesCollection.items);
        this.error = tncDetails.error;
        this.tncVersion = tncDetails.data.landingPage.sys.publishedVersion;
        this.currentTncVersion = this.userService.getTermsAndConditionsAcceptedVersion();
        this.loggingService.log('Current tnc version - - - ' + this.currentTncVersion, 'Latest tnc version - - - ' + this.tncVersion);
        this.backgroundImageUrl = tncDetails?.data?.landingPage.backgroundImage.url;
        this.pageHeading = this.pageLabels["label.title.terms.conditions"];
        this.mailToEmailAddress = this.pageLabels["mailto.email.address"];
        this.emailAddressText = this.pageLabels["support.email.address"];
        this.declineButtonText = this.pageLabels["login.termsconditions.label.decline"];
        this.acceptButtonText = this.pageLabels["login.termsconditions.label.accept"];
        this.LogoImageUrl = tncDetails?.data?.landingPage.sectionsCollection.items[0].contentImage.url;
        this.copyrightText = this.pageLabels["label.copyright.text"];
        this.pageContent = documentToHtmlString(tncDetails.data.landingPage.sectionsCollection.items[2].contents.json).replace(this.contentfulConfig.summaryRegExp, this.contentfulConfig.summaryValueToReplace);
        this.loadingService.setLoading(false);

        if (this.tncVersion <= this.currentTncVersion) {
          this.redirectToDashboard();
        }
      }
    });
  }
}
