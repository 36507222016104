import { Component, Input } from '@angular/core';
import { InfoIconComponent } from '../info-icon/info-icon.component';

@Component({
  selector: 'app-info-text-box',
  standalone: true,
  imports: [InfoIconComponent],
  templateUrl: './info-text-box.component.html',
  styleUrls: ['./info-text-box.component.scss']
})
export class InfoTextBoxComponent {
  @Input() infoText;
}
